<template>
  <div class="game_home">
    <div class="user_login_box">
      <!-- <div class="goback passIconfont  icon-ftfanhui"
           @click="close(false)"></div>-->
      <div class="close passIconfont  icon-ftshanchu"
           @click="close()"></div>
      <div class="ft_logo"></div>
      <div class="cat_login_box">
        <div class="tips_title">
          {{$t('toast.bannerGameTxt1')}}
        </div>
        {{$t('toast.bannerGameTxt2')}}
      </div>
      <div class="tips_comfirm"
           @click="confirm()">
        {{$t('toast.comfirmBtn2')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () { },
  methods: {
    key () {
      return this.$route.path
    },
    close () {
      alert('关闭webview')
    },
    confirm () {
      this.$router.push({ path: '/success' })
    }
  }
}
</script>


<style scoped lang="scss">
@media (min-width: 1600px) {
  .game_home {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    .user_login_box {
      width: 700px;
      height: 640px;
      background: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      // padding-top: (195vw/19.2);
      box-sizing: border-box;
      .ft_logo {
        width: 272px;
        height: 113px;
        background: url("../../../assets/images/ft_logo@1x.png") no-repeat;
        background-size: contain;
        padding-top: 8px;
        margin: 0 auto;
      }
      .close {
        width: 25px;
        height: 25px;
        font-size: 25px;
        position: absolute;
        top: 30px;
        right: 39px;
        cursor: pointer;
        color: #707070;
      }
      .goback {
        width: 25px;
        height: 25px;
        font-size: 25px;
        position: absolute;
        top: 30px;
        left: 39px;
        cursor: pointer;
        color: #707070;
      }
      .cat_login_box {
        width: 100%;
        height: 260px;
        padding: 17px 50px;
        box-sizing: border-box;
        font-size: 26px;
        color: #575757;
        text-indent: 2em;
        overflow-y: auto;
        .tips_title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .tips_comfirm {
        width: 600px;
        height: 80px;
        background: #ff7391;
        opacity: 1;
        border-radius: 6px;
        margin: 0 auto;
        margin-top: 35px;
        font-size: 32px;
        color: #fff;
        text-align: center;
        line-height: 80px;
        cursor: pointer;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .game_home {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    .user_login_box {
      width: 700 * 0.85px;
      height: 640 * 0.85px;
      background: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      // padding-top: (195vw/19.2);
      box-sizing: border-box;

      .ft_logo {
        width: 272 * 0.85px;
        height: 113 * 0.85px;
        background: url("../../../assets/images/ft_logo@1x.png") no-repeat;
        background-size: contain;
        padding-top: 8 * 0.85px;
        margin: 0 auto;
      }
      .close {
        width: 25 * 0.85px;
        height: 25 * 0.85px;
        font-size: 25 * 0.85px;
        position: absolute;
        top: 30 * 0.85px;
        right: 39 * 0.85px;
        cursor: pointer;
        color: #707070;
      }
      .goback {
        width: 25 * 0.85px;
        height: 25 * 0.85px;
        font-size: 25 * 0.85px;
        position: absolute;
        top: 30 * 0.85px;
        left: 39 * 0.85px;
        cursor: pointer;
        color: #707070;
      }
      .cat_login_box {
        width: 100%;
        height: 260 * 0.85px;
        padding: 17 * 0.85px 50 * 0.85px;
        box-sizing: border-box;
        font-size: 26 * 0.85px;
        color: #575757;
        text-indent: 2em;
        overflow-y: auto;
        .tips_title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .tips_comfirm {
        width: 600 * 0.85px;
        height: 80 * 0.85px;
        background: #ff7391;
        opacity: 1;
        border-radius: 6 * 0.85px;
        margin: 0 auto;
        margin-top: 35 * 0.85px;
        font-size: 32 * 0.85px;
        color: #fff;
        text-align: center;
        line-height: 80 * 0.85px;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width:1199px) {
  .game_home {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    .user_login_box {
      width: 700 * 0.7px;
      height: 640 * 0.7px;
      background: rgba(255, 255, 255, 1);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      // padding-top: (195vw/19.2);
      box-sizing: border-box;

      .ft_logo {
        width: 272 * 0.7px;
        height: 113 * 0.7px;
        background: url("../../../assets/images/ft_logo@1x.png") no-repeat;
        background-size: contain;
        padding-top: 8 * 0.7px;
        margin: 0 auto;
      }
      .close {
        width: 25 * 0.7px;
        height: 25 * 0.7px;
        font-size: 25 * 0.7px;
        position: absolute;
        top: 30 * 0.7px;
        right: 39 * 0.7px;
        cursor: pointer;
        color: #707070;
      }
      .goback {
        width: 25 * 0.7px;
        height: 25 * 0.7px;
        font-size: 25 * 0.7px;
        position: absolute;
        top: 30 * 0.7px;
        left: 39 * 0.7px;
        cursor: pointer;
        color: #707070;
      }
      .cat_login_box {
        width: 100%;
        height: 260 * 0.7px;
        padding: 17 * 0.7px 50 * 0.7px;
        box-sizing: border-box;
        font-size: 26 * 0.7px;
        color: #575757;
        text-indent: 2em;
        overflow-y: auto;
        .tips_title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .tips_comfirm {
        width: 600 * 0.7px;
        height: 80 * 0.7px;
        background: #ff7391;
        opacity: 1;
        border-radius: 6 * 0.7px;
        margin: 0 auto;
        margin-top: 35 * 0.7px;
        font-size: 32 * 0.7px;
        color: #fff;
        text-align: center;
        line-height: 80 * 0.7px;
        cursor: pointer;
      }
    }
  }
}
</style>
